import { useEffect, useState } from 'react';

import CONFIG from '../../../utils/config.js';
import { required, length, email } from '../../../utils/validator.js';

import './contact-form.styles.scss';

const ContactForm = () => {
  const [state, setState] = useState({
    loginForm: {
      name: {
        value: '',
        valid: false,
        validators: [required, length({ min: 2 })],
      },
      email: {
        value: '',
        valid: false,
        validators: [required, email],
      },
      subject: {
        value: '',
        valid: false,
        validators: [required],
      },
      message: {
        value: '',
        valid: false,
        validators: [required, length({ min: 5 })],
      },
    },
    formIsValid: false,
  });
  const [btnClassState, setBtnClassState] = useState('');
  const [btnTextState, setBtnTextState] = useState('Submit');
  const [selectClass, setSelectClass] = useState('');

  const clearForm = () => {
    setSelectClass('');
    setState({
      loginForm: {
        name: {
          value: '',
          valid: false,
          validators: [required, length({ min: 2 })],
        },
        email: {
          value: '',
          valid: false,
          validators: [required, email],
        },
        subject: {
          value: '',
          valid: false,
          validators: [required],
        },
        message: {
          value: '',
          valid: false,
          validators: [required, length({ min: 5 })],
        },
      },
      formIsValid: false,
    });
  };

  const handleChange = (e) => {
    const input = e.target.name;
    if (!selectClass && input === 'subject') {
      setSelectClass('change');
    }
    const value = e.target.value;
    setState((prevState) => {
      let isValid = true;
      for (const validator of prevState.loginForm[input].validators) {
        isValid = isValid && validator(value);
      }
      const updatedForm = {
        ...prevState.loginForm,
        [input]: {
          ...prevState.loginForm[input],
          valid: isValid,
          value: value,
        },
      };
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        loginForm: updatedForm,
        formIsValid: formIsValid,
      };
    });
  };

  useEffect(() => {
    if (state.formIsValid) {
      setBtnClassState('valid');
    } else {
      setBtnClassState('');
    }
  }, [state]);

  useEffect(() => {
    setTimeout(() => {
      setBtnTextState('Submit');
    }, 5000);
  }, [btnTextState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state.formIsValid) return;

    setBtnTextState('Sending...');
    const res = await fetch(`${CONFIG.apiUrl}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: state.loginForm.name.value,
        email: state.loginForm.email.value,
        subject: state.loginForm.subject.value,
        message: state.loginForm.message.value,
      }),
    });
    const { message } = await res.json();
    if (res.status === 200) {
      clearForm();
      setBtnTextState(message);
    } else {
      setBtnTextState(message);
    }
  };

  return (
    <div className='contact-form'>
      <div className='contact-form-container'>
        <form className='form'>
          <div className='user-info'>
            <div className='input-container'>
              <label htmlFor='name'>Name</label>
              <input
                type='text'
                placeholder='Midwest Avionics'
                name='name'
                value={state.loginForm.name.value}
                onChange={handleChange}
                spellCheck='false'
              />
            </div>
            <div className='input-container'>
              <label htmlFor='email'>Email</label>
              <input
                type='email'
                placeholder='contact@midwestav.ca'
                name='email'
                value={state.loginForm.email.value}
                onChange={handleChange}
                spellCheck='false'
              />
            </div>
          </div>
          <div className='user-details'>
            <div className='input-container'>
              <label htmlFor='subject'>Subject</label>
              <select
                name='subject'
                className={selectClass}
                value={state.loginForm.subject.value}
                onChange={handleChange}
              >
                <option value='' disabled hidden>
                  Please select a subject
                </option>
                <optgroup>
                  <option value='General inquires'>General inquires</option>
                  <option value='Installation'>Installations</option>
                  <option value='Part sales'>Part sales</option>
                  <option value='Repair'>Repairs</option>
                  <option value='Troubleshooting'>Troubleshooting</option>
                  <option value='AOG support'>AOG support</option>
                  <option value='Recertification'>Recertifications</option>
                  <option value='Other'>Other</option>
                </optgroup>
              </select>
            </div>
            <div className='input-container'>
              <label htmlFor='message'>Message</label>
              <textarea
                name='message'
                rows='8'
                placeholder='Enter message here'
                value={state.loginForm.message.value}
                onChange={handleChange}
                spellCheck='false'
              ></textarea>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            className={['form-submit', btnClassState].join(' ')}
          >
            {btnTextState}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
