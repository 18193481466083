import { useEffect } from 'react';

import Footer from '../../components/global-components/footer/footer.component';
import ServicesHeader from '../../components/services-components/services-header/services-header.component';
import ServicesContent from '../../components/services-components/services-content/services-content.component';

import './services.styles.scss';

const Services = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  return (
    <div className='services-page'>
      <main>
        <ServicesHeader />
        <ServicesContent />
      </main>
      <Footer />
    </div>
  );
};

export default Services;
