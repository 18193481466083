import { useEffect } from 'react';

import Footer from '../../components/global-components/footer/footer.component';
import ContactBox from '../../components/contact-components/contact-box/contact-box.component';

import './contact.styles.scss';

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  return (
    <div className='contact-page'>
      <ContactBox />
      <Footer />
    </div>
  );
};

export default Contact;
