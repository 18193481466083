import AboutHeaderImage from '../../../assets/about-header-image.jpg';

import './about-header.styles.scss';

const AboutHeader = () => {
  return (
    <section
      className='about-header'
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${AboutHeaderImage})`,
      }}
    >
      <article className='about-header-textbox'>
        <h1>About Us</h1>
        <p>
          Learn more about what we do best, what we stand for, what our goals
          are, and who we are here at Midwest Avionics.
        </p>
      </article>
      <div className='curve'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
          <path
            fill='#e9ecef'
            fillOpacity='1'
            d='M0,224L120,234.7C240,245,480,267,720,266.7C960,267,1200,245,1320,234.7L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z'
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default AboutHeader;
