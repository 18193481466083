import { useContext, useEffect, useState } from 'react';

import { ServiceContext } from '../../../contexts/services.context';
import ServiceSales from '../service-sales/service-sales.component';
import ServiceMaintenance from '../service-maintenance/service-maintenance.component';
import ServiceInstallations from '../service-installations/service-installations.component';

import './services-content.styles.scss';

const ServicesContent = () => {
  const [servicesClasses, setServicesClasses] = useState(['', '', '']);
  const { currentService, setCurrentService } = useContext(ServiceContext);

  const services = [
    <ServiceMaintenance />,
    <ServiceInstallations />,
    <ServiceSales />,
  ];

  useEffect(() => {
    const newServicesClasses = servicesClasses.map((val, i) => {
      if (val && i !== currentService) return '';
      if (i === +currentService) return 'active';
      return '';
    });
    setServicesClasses(newServicesClasses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentService]);

  const tabsClickHandler = (e) => {
    const service = e.target.dataset.service;
    setCurrentService(service);
  };

  return (
    <section className='services-content'>
      <div className='services-title'>
        <h1>Take a look through what we offer</h1>
        <div className='tabs'>
          <p
            onClick={tabsClickHandler}
            className={servicesClasses[0]}
            data-service='0'
          >
            Maintenance
          </p>
          <p
            onClick={tabsClickHandler}
            className={servicesClasses[1]}
            data-service='1'
          >
            Installations
          </p>
          <p
            onClick={tabsClickHandler}
            className={servicesClasses[2]}
            data-service='2'
          >
            Part Sales
          </p>
        </div>
      </div>
      {services[currentService]}
    </section>
  );
};

export default ServicesContent;
