import { createContext, useReducer } from 'react';

import { createAction } from '../utils/reducer';

export const ServiceContext = createContext({
  currentService: null,
  setCurrentService: () => null,
});

export const USER_ACTION_TYPES = {
  SET_CURRENT_SERVICE: 'SET_CURRENT_SERVICE',
};

const serviceReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.SET_CURRENT_SERVICE:
      return {
        ...state,
        currentService: payload,
      };
    default:
      throw new Error(`Unhandled type ${type} in serviceReducer`);
  }
};

const INITIAL_STATE = {
  currentService: 0,
};

export const ServiceProvider = ({ children }) => {
  const [{ currentService }, dispatch] = useReducer(
    serviceReducer,
    INITIAL_STATE
  );

  const setCurrentService = (service) => {
    dispatch(createAction(USER_ACTION_TYPES.SET_CURRENT_SERVICE, service));
  };

  const value = { currentService, setCurrentService };
  return (
    <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
  );
};
