import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ServiceContact from '../service-contact/service-contact.component';

import Beachcraft from '../../../assets/Beechcraft_Logo.png';
import Cessna from '../../../assets/Cessna-Logo.png';
import Piper from '../../../assets/Piper_logo.png';
import Bombardier from '../../../assets/Bombardier-logo.png';
import Embraer from '../../../assets/Embraer_logo.png';
import DassaultSystèmes from '../../../assets/Dassault_Systèmes_logo.png';
import Pilatus from '../../../assets/Pilatus_logo.png';
import LM from '../../../assets/LM-logo.png';
import Airbus from '../../../assets/Airbus-Logo.png';
import Boeing from '../../../assets/Boeing_full_logo.png';
import ATR from '../../../assets/ATR_logo.png';
import Sikorsky from '../../../assets/Sikorsky_Aircraft_Logo.png';

import GarminLogo from '../../../assets/garmin-logo.png';
import RCAllenInstruments from '../../../assets/RC-Allen-Instruments.png';
import BendixKing from '../../../assets/BendixKing-Logo.png';
import Luma from '../../../assets/Luma-Logo.png';
import Avidyne from '../../../assets/Avidyne-Logo.png';
import L3Harris from '../../../assets/L3Harris-Logo.png';
import Artex from '../../../assets/artex_logo.png';
import Genesys from '../../../assets/GenesysAerosystems_Logo.png';
import MidContinent from '../../../assets/midcontinent_logo.png';
import Aspen from '../../../assets/aspen_logo.png';
import DavidClark from '../../../assets/dclark-logo.png';
import PSEngineering from '../../../assets/psengineering_logo.png';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './service-installations.styles.scss';

const ServiceInstallations = () => {
  return (
    <div className='service-installations-container'>
      <div className='installations'>
        <div className='aircraft-logos'>
          <LazyLoadImage src={Beachcraft} effect='blur' alt='Beechcraft Logo' />
          <LazyLoadImage src={Cessna} alt='Cessna Logo' effect='blur' />
          <LazyLoadImage src={Piper} alt='Piper Logo' effect='blur' />
          <LazyLoadImage src={Bombardier} alt='Bombardier Logo' effect='blur' />
          <LazyLoadImage src={Embraer} alt='Embraer Logo' effect='blur' />
          <LazyLoadImage
            src={DassaultSystèmes}
            alt='Dassault Systèmes Logo'
            effect='blur'
          />
          <LazyLoadImage src={Pilatus} alt='Pilatus Logo' effect='blur' />
          <LazyLoadImage src={LM} alt='Lockheed Martin Logo' effect='blur' />
          <LazyLoadImage src={Airbus} alt='Airbus Logo' effect='blur' />
          <LazyLoadImage src={Boeing} alt='Boeing Logo' effect='blur' />
          <LazyLoadImage src={ATR} alt='ATR Logo' effect='blur' />
          <LazyLoadImage src={Sikorsky} effect='blur' alt='Sikorsky Logo' />
        </div>
        <div className='install-card'>
          <ion-icon name='build-outline'></ion-icon>
          <h2>Installations</h2>
          <p>
            As an AMO we are able to install any system that you want inside
            your aircraft. Like the following...
          </p>
          <ul>
            <li>ADS-B Tranponder</li>
            <li>Satellite phone</li>
            <li>WiFi</li>
            <li>Intercom system</li>
            <li>Flat Planel systems</li>
            <li>TCAS</li>
            <li>TAWS</li>
            <li>EGPWS</li>
            <li>FMS</li>
            <li>GPS</li>
            <li>... & many more!</li>
          </ul>
        </div>
        <div className='working-on-card'>
          <ion-icon name='hammer-outline'></ion-icon>
          <h2>What we work on</h2>
          <p>
            We are certified to install all your favourite brands and can handle
            the vast majority of the parts that they carry. We are also familar
            with working with all major aircrafts manufacturers, no part or
            aircraft too big nor too small, we've got all your bases covered!
          </p>
        </div>
        <div className='aircraft-brands'>
          <LazyLoadImage src={GarminLogo} alt='Garmin Logo' effect='blur' />
          <LazyLoadImage src={Aspen} alt='Aspen Avionics Logo' effect='blur' />
          <LazyLoadImage src={BendixKing} alt='BendixKing Logo' effect='blur' />
          <LazyLoadImage src={Artex} alt='Artex Logo' effect='blur' />
          <LazyLoadImage src={Avidyne} alt='Avidyne Logo' effect='blur' />
          <LazyLoadImage src={L3Harris} alt='L3Harris Logo' effect='blur' />
          <LazyLoadImage src={Luma} effect='blur' alt='Luma Logo' />
          <LazyLoadImage src={Genesys} effect='blur' alt='Genesys Logo' />
          <LazyLoadImage
            src={MidContinent}
            alt='MidContinent Logo'
            effect='blur'
          />
          <LazyLoadImage
            src={PSEngineering}
            effect='blur'
            alt='PS Engineering Logo'
          />
          <LazyLoadImage
            src={RCAllenInstruments}
            alt='RC Allen Logo'
            effect='blur'
          />
          <LazyLoadImage
            Aspen
            src={DavidClark}
            alt='David Clark Logo'
            effect='blur'
          />
        </div>
      </div>
      <p className='service-maintenance-finish'>
        Still not sure if we can get the job done? Just{' '}
        <Link to='/contact'>give us a shout</Link> and we will get back to you
        ASAP.
      </p>
      <ServiceContact service='1' />
    </div>
  );
};

export default ServiceInstallations;
