import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './home-about.styles.scss';

const HomeAbout = () => {
  const yearsRunning = new Date().getFullYear() - 1984;
  return (
    <section className='home-about'>
      <div className='about-container'>
        <div className='about-textbox'>
          <p className='subheading'>About Us</p>
          <h1>Who we are here at Midwest Avionics</h1>
        </div>
        <div className='about-block'>
          <article className='about-content-textbox'>
            <h3 className='about-content-heading'>Founded in 1984</h3>
            <p>
              In this business for {yearsRunning} years, we've seen it all and
              done it all. For any of your avionics needs let our avionic
              professionals help you.
            </p>
          </article>
          <div className='about-content-image image--1'>
            <LazyLoadImage
              effect='blur'
              src='https://images.unsplash.com/photo-1532182157363-907c9b263f3e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
              alt='Old grey biplane'
            />
          </div>
        </div>
        <div className='about-block about-block--2'>
          <div className='about-content-image image--2'>
            <LazyLoadImage
              effect='blur'
              src='https://images.unsplash.com/photo-1525703758695-469daeaad6c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80'
              alt='Air Canada plane on snowy runway'
            />
          </div>
          <article className='about-content-textbox'>
            <h3 className='about-content-heading'>AMO by Transport Canada</h3>
            <p>
              As an Approved Maintenance Organization by Transport Canada for
              the past {Math.floor((yearsRunning - 18) / 10)} decades you can
              have confidence in our work.
            </p>
          </article>
        </div>
        <div className='about-block'>
          <article className='about-content-textbox'>
            <h3 className='about-content-heading'>
              Leading avionics dealer & installer
            </h3>
            <p>
              With our longstanding relations with countless avionics
              manufacturers and suppliers and our expertise in aviation, allow
              us to help you with all of your avionic part purchases and
              installations.
            </p>
          </article>
          <div className='about-content-image image--3'>
            <LazyLoadImage
              effect='blur'
              src='https://images.unsplash.com/photo-1575930938364-8a137c5fc47f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
              alt='Cockpit of aircraft sight of ground below'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeAbout;
