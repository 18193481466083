import { useEffect } from 'react';

import HomeVideo from '../../components/home-components/home-video/home-video.component';
import Hero from '../../components/home-components/hero/hero.component';
import HomeServices from '../../components/home-components/home-services/home-services.component';
import HomeAbout from '../../components/home-components/home-about-us/home-about.component';
// import HomeTestimonials from '../../components/home-components/home-testimonials/home-testimonials.component';
import HomeContact from '../../components/home-components/home-contact/home-contact.component';
import Footer from '../../components/global-components/footer/footer.component';

import './home.styles.scss';

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  return (
    <div>
      <main>
        <HomeVideo />
        <Hero />
        <HomeServices />
        <HomeAbout />
        <div className='space'></div>
        {/* <HomeTestimonials /> */}
        <HomeContact />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
