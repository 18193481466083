import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ServiceContext } from '../../../contexts/services.context';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import CompanyLogo4 from '../../../assets/fastair-logo-main.png';
import CompanyLogo3 from '../../../assets/westjet-logo-main.png';
import CompanyLogo5 from '../../../assets/aircanada-logo-main.png';
import CompanyLogo2 from '../../../assets/perimeter-logo-main.png';
import CompanyLogo1 from '../../../assets/customheli-logo-main.png';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './home-services.styles.scss';

const HomeServices = () => {
  const { setCurrentService } = useContext(ServiceContext);

  const servicesClick = (e) => {
    setCurrentService(e.target.dataset.service);
  };

  return (
    <section className='home-services'>
      <div className='services-container'>
        <div className='service-textbox'>
          <span className='subheading'>Services</span>
          <h1>What we do best!</h1>
        </div>
        <div className='service-feature-boxes'>
          <article className='service-box'>
            <ion-icon name='construct-outline'></ion-icon>
            <h2>Maintenance</h2>
            <p>
              With our expertise we have you covered for any maintenance you may
              need on your aircraft... Recertifications, testing, repairs,
              troubleshooting, we got you covered.
            </p>
            <Link
              to='/services'
              onClick={servicesClick}
              data-service='0'
              className='btn'
            >
              Maintenance Details
            </Link>
          </article>
          <article className='service-box'>
            <ion-icon name='settings-outline'></ion-icon>
            <h2>Installations</h2>
            <p>
              Installs are available for all aviation products from all your
              favourite brands, everything from keeping you connected with WiFi
              to your navigation & radio tools.
            </p>
            <Link
              to='/services'
              onClick={servicesClick}
              data-service='1'
              className='btn'
            >
              Install Details
            </Link>
          </article>
          <article className='service-box'>
            <ion-icon name='airplane-outline'></ion-icon>
            <h2>Part Sales</h2>
            <p>
              With in-house expertise and relationships with leading
              manufacturers and suppliers, allow us to assist with all your
              parts needs.
            </p>
            <Link
              to='/services'
              onClick={servicesClick}
              data-service='2'
              className='btn'
            >
              Part Details
            </Link>
          </article>
        </div>
      </div>
      <div className='about-trusted'>
        <p className='about-trusted-text'>
          Trusted by hundreds of aviation companies all over the globe, like
          some of our friends here!
        </p>
        <div className='about-trusted-logos'>
          <LazyLoadImage
            className='trusted-logo'
            src={CompanyLogo1}
            effect='blur'
          />
          <LazyLoadImage
            className='trusted-logo'
            src={CompanyLogo2}
            effect='blur'
          />
          <LazyLoadImage
            className='trusted-logo'
            src={CompanyLogo3}
            effect='blur'
          />
          <LazyLoadImage
            className='trusted-logo'
            src={CompanyLogo4}
            effect='blur'
          />
          <LazyLoadImage
            className='trusted-logo'
            src={CompanyLogo5}
            effect='blur'
          />
        </div>
      </div>
      <div className='curve'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
          <path
            fill='#e9ecef'
            fillOpacity='1'
            d='M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default HomeServices;
