import { Link } from 'react-router-dom';

import './home-contact.styles.scss';

const HomeContact = () => {
  return (
    <section className='home-contact'>
      <div className='home-contact-container'>
        <div className='home-contact-textbox'>
          <h1 className='home-contact-textbox-title'>
            We know avionics, <br />
            let us help.
          </h1>
          <p>
            For all your avionics needs, let us help you. Get in contact today!
          </p>
        </div>

        <Link to='/contact' className='btn'>
          Contact Us
        </Link>

        <div className='contact-direct'>
          <a href='mailto:contact@midwestav.ca'>
            <div className='contact-box'>
              <ion-icon name='mail-outline'></ion-icon>
              <p className='title'>Email Us</p>
              <p className='info'>contact@midwestav.ca</p>
            </div>
          </a>
          <a href='tel:+12048880296'>
            <div className='contact-box'>
              <ion-icon name='call-outline'></ion-icon>
              <div className='call-hours'>
                <p className='title'>Call Us</p>
                <p className='hours'>8am - 4:30pm CST | Mon - Fri</p>
              </div>
              <p className='info'>(204) 888-0296</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default HomeContact;
