import './home-video.styles.scss';

const HomeVideo = () => {
  return (
    <>
      <video
        className='home-video'
        loop
        muted
        playsInline
        autoPlay
        poster='/assets/home-video-cover.jpg'
      >
        <source
          src='https://player.vimeo.com/external/563130035.hd.mp4?s=5bd311f698519ffa90aa5136b96d1e86aa78d107&profile_id=175'
          type='video/mp4'
        />
        <source
          src='/assets/player_vimeo_com-563130035.hd.ogv'
          type='video/ogv'
        />
        <source src='/assets/563130035.hd.webm' type='video/webm' />
      </video>
      <div className='gradient'></div>
    </>
  );
};

export default HomeVideo;
