import { Routes, Route, Navigate } from 'react-router-dom';

import Home from './routes/home/home.component';
import Contact from './routes/contact/contact.component';
import Services from './routes/services/services.component';
import About from './routes/about/about.component';
import Navigation from './components/global-components/navigation/navigation.component';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  );
}

export default App;
