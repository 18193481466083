import AboutContact from '../about-contact/about-contact.component';

import './about-content.styles.scss';

const AboutContent = () => {
  const yearsRunning = new Date().getFullYear() - 1984;
  return (
    <section className='about-content'>
      <div className='about-content-container'>
        <article className='about-content-block'>
          <h1 className='about-title'>What we're all about!</h1>
          <p className='about-text'>
            Midwest Avionics, founded in 1984, has grown, evolved, and adapted
            to meet the ever-changing needs of our customers. Headquartered in
            Winnipeg, Manitoba, at the Winnipeg James Armstrong Richardson
            International Airport. Midwest has been providing avionic solutions
            for {yearsRunning} years with a team of specialists providing you
            with work that we stand behind and the service you deserve.
          </p>
        </article>
        <div className='about-content-goals'>
          <div className='about-content-goal'>
            <h2 className='about-title'>What we do best</h2>
            <p className='about-text'>
              We are the best in class for all your avionic needs. This includes
              installing your favorite systems in your aircraft to helping you
              out with your grounded plane in a timely matter. Anything
              avionics, we've got you.
            </p>
          </div>
          <div className='about-content-goal'>
            <h2 className='about-title'>Our Goals</h2>
            <p className='about-text'>
              After being in the business for {yearsRunning} years, we still
              stick to the same goals we had on day one. Providing unmatched
              quality work and unbeatable customer support. And finally,
              building long-lasting partnerships with customers, suppliers,
              employees, and all.
            </p>
          </div>
        </div>
      </div>
      <div className='about-content-info-wrap'>
        <div className='about-content-info'>
          <p className='about-content-text'>
            As an AMO for the past {Math.floor(yearsRunning / 10)} decades, let
            us handle all your avionic needs, with work that you can trust, and
            work that we stand behind proudly.
          </p>
          <span className='years'>{yearsRunning}</span>
        </div>
      </div>
      <AboutContact />
    </section>
  );
};

export default AboutContent;
