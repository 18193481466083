import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import BackToTop from '../../../assets/backtotop.png';

import TextMidwest from '../../../assets/textmidwest.png';

import './footer.styles.scss';

const Footer = () => {
  const backToTopHandler = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className='footer'>
      <div className='footer-container'>
        <div className='footer-column footer-column--logo'>
          <Link to='/' className='footer-logo'>
            <img src={TextMidwest} alt='Midwest Avionics logo' />
          </Link>
          <p className='copyright'>
            Copyright © 1984 - {currentYear} by Midwest Avionics, Inc. All
            rights reserved.
          </p>
        </div>
        <div className='footer-column'>
          <p className='footer-heading'>Menu</p>
          <p>
            <Link to='/' className='footer-info'>
              Home
            </Link>
          </p>
          <p>
            <Link to='/services' className='footer-info'>
              Services
            </Link>
          </p>
          <p>
            <Link to='/about-us' className='footer-info'>
              About Us
            </Link>
          </p>
          <p>
            <Link to='/contact' className='footer-info'>
              Contact Us
            </Link>
          </p>
        </div>
        <div className='footer-column'>
          <p className='footer-heading'>Contact</p>
          <p>
            <a className='footer-info' href='tel:+12048880296'>
              (204) 888-0296
            </a>
          </p>
          <p>
            <a className='footer-info' href='mailto:contact@midwestav.ca'>
              contact@midwestav.ca
            </a>
          </p>
        </div>
        <div className='footer-column'>
          <p className='footer-heading'>Address</p>
          <div className='footer-address-container'>
            <a
              className='footer-info'
              rel='noreferrer'
              noopener='true'
              target='_blank'
              href='https://www.google.com/maps/place/20+Hangar+Line+Rd+%2310,+Winnipeg,+MB+R3J+3Y7/@49.8934385,-97.2339752,21z/data=!4m5!3m4!1s0x52ea7346fda78e31:0x6d4e7ea928f269e4!8m2!3d49.8934186!4d-97.2338666'
            >
              <p>20 Hangar Line Road #10</p>
              <p>Winnipeg, MB R3J 3Y7</p>
              <p>Canada</p>
            </a>
          </div>
        </div>
        <div className='footer-column footer-column--backtotop'>
          <LazyLoadImage
            onClick={backToTopHandler}
            className='footer-backtotop'
            src={BackToTop}
            alt='Back to top'
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
