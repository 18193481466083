import { useEffect } from 'react';

import Footer from '../../components/global-components/footer/footer.component';
import AboutContent from '../../components/about-components/about-content/about-content.component';
import AboutHeader from '../../components/about-components/about-header/about-header.component';

import './about.styles.scss';

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  return (
    <div className='about-page'>
      <main>
        <AboutHeader />
        <AboutContent />
      </main>
      <Footer />
    </div>
  );
};

export default About;
