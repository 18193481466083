import { Link } from 'react-router-dom';

import './service-contact.styles.scss';

const PartsTitle = () => {
  return (
    <div className='home-contact-textbox'>
      <h2>
        Need parts,
        <br />
        talk to the experts.
      </h2>
      <p>
        With in-house expertise let us assist with all your parts needs. <br />
        Get in contact today! &rarr;
      </p>
    </div>
  );
};
const InstallationsTitle = () => {
  return (
    <div className='home-contact-textbox'>
      <h2>
        Need an install?
        <br />
        Let us help.
      </h2>
      <p>
        As an <strong>Approved Maintenance Organization</strong> let us do the
        hard work.
        <br />
        Let's talk! &rarr;
      </p>
    </div>
  );
};
const MaintenanceTitle = () => {
  return (
    <div className='home-contact-textbox'>
      <h2>
        If it needs fixin',
        <br />
        we've got you covered.
      </h2>
      <p>
        For all your maintenance, we got your back. Just give us a shout! &rarr;
      </p>
    </div>
  );
};

const ServiceContact = ({ service }) => {
  const services = [
    <MaintenanceTitle />,
    <InstallationsTitle />,
    <PartsTitle />,
  ];
  return (
    <div className='service-contact-container'>
      {services[service]}

      <Link to='/contact' className='btn'>
        Let's Talk &rarr;
      </Link>

      {/* <div className='contact-direct'>
        <a href='mailto:midav@mts.net'>
          <div className='contact-box'>
            <ion-icon name='mail-outline'></ion-icon>
            <p className='title'>Email Us</p>
            <p className='info'>midav@mts.net</p>
          </div>
        </a>
        <a href='tel:+12048880296'>
          <div className='contact-box'>
            <ion-icon name='call-outline'></ion-icon>
            <div className='call-hours'>
              <p className='title'>Call Us</p>
              <p className='hours'>8am - 5pm CST | Mon - Fri</p>
            </div>
            <p className='info'>(204) 888-0296</p>
          </div>
        </a>
      </div> */}
    </div>
  );
};

export default ServiceContact;
