import { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import TextMidwest from '../../../assets/textmidwest.png';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './navigation.styles.scss';

const Navigation = () => {
  const [scrolled, setScrolled] = useState(false);
  const [openedNav, setOpenedNav] = useState(false);

  const onScroll = () => {
    window.scrollY !== 0 ? setScrolled(true) : setScrolled(false);
  };

  const btnClickHandler = () => {
    if (openedNav) return setOpenedNav(false);
    return setOpenedNav(true);
  };

  const linkClickHandler = () => {
    return setOpenedNav(false);
  };

  useEffect(() => {
    document.body.style.overflowY = openedNav ? 'hidden' : 'scroll';

    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, [openedNav]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <header className={`navigation ${scrolled ? 'scroll' : ''}`}>
        <div className={`navigation-container ${openedNav ? 'open' : ''}`}>
          <Link to='/' className='navigation-logo'>
            <LazyLoadImage
              effect='blur'
              src={TextMidwest}
              alt='Midwest Avionics logo'
              className='logo'
            />
          </Link>
          <nav className='navigation-links'>
            <Link to='/' className='navigation-link' onClick={linkClickHandler}>
              Home
            </Link>
            <Link
              to='/services'
              className='navigation-link'
              onClick={linkClickHandler}
            >
              Services
            </Link>
            <Link
              to='/about-us'
              className='navigation-link'
              onClick={linkClickHandler}
            >
              About Us
            </Link>
            <Link
              to='/contact'
              className='navigation-link contact'
              onClick={linkClickHandler}
            >
              Contact Us
            </Link>
          </nav>
          <button class='btn-mobile-nav' onClick={btnClickHandler}>
            <ion-icon class='icon-mobile-nav' name='menu-outline'></ion-icon>
            <ion-icon class='icon-mobile-nav' name='close-outline'></ion-icon>
          </button>
        </div>
      </header>
      <Outlet />
    </>
  );
};

export default Navigation;
