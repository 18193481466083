import { Link } from 'react-router-dom';

import ServiceContact from '../service-contact/service-contact.component';

import './service-maintenance.styles.scss';

const ServiceMaintenance = () => {
  return (
    <div className='service-maintenance-container'>
      <div className='service-maintenance-cards'>
        <div className='card'>
          <ion-icon name='shield-checkmark-outline'></ion-icon>
          <div className='card-content'>
            <h2>Recertifications</h2>
            <p>
              We are able to perform all recertifications on your aircraft to
              keep your plane up in the air.
            </p>
            <ul>
              <li>Transponder / Encoder recertification</li>
              <li>Altimeter recertification</li>
              <li>Cockpit Voice Recorder (CVR) recertification </li>
              <li>Pitot Static recertification</li>
              <li>Emergency Locator Transmitter (ELT) recertification</li>
              <li>... & many more!</li>
            </ul>
          </div>
        </div>
        <div className='card'>
          <ion-icon name='construct-outline'></ion-icon>
          <div className='card-content'>
            <h2>Repairs</h2>
            <p>
              You got a problem let us fix it. Please note some services may be
              subject to a minimum evaluation fee.
            </p>
            <ul>
              <li>Autopilot repair</li>
              <li>Annunciator modifications</li>
              <li>Bench repair</li>
              <li>Panel replacements</li>
              <li>Wiring harness repair</li>
              <li>... & many more!</li>
            </ul>
          </div>
        </div>
        <div className='card'>
          <ion-icon name='flask-outline'></ion-icon>
          <div className='card-content'>
            <h2>Testing</h2>
            <p>
              We are able to conduct all types of avionic testing depending on
              your needs.
            </p>
            <ul>
              <li>Pilot Static System leak testing</li>
              <li>Battery power supply testing</li>
              <li>Nicat / lead acid battery testing</li>
              <li>... & many more!</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='service-support-cards'>
        <div className='card'>
          <ion-icon name='cog-outline'></ion-icon>
          <div className='card-content'>
            <h2>Troubleshooting</h2>
            <p>
              Your aircraft has a problem and can't quite figure it out? We can
              help you out, we are able to troubleshoot your aircraft.
            </p>
          </div>
        </div>
        <div className='card'>
          <ion-icon name='airplane-outline'></ion-icon>
          <div className='card-content'>
            <h2>AOG Support</h2>
            <p>
              If you're suffering an AOG we've got your back. We know the clock
              is running when a aircraft is grounded so we will do our best to
              fix the problem ASAP!
            </p>
          </div>
        </div>
      </div>
      <p className='service-maintenance-finish'>
        Job you need done isn't listed above? Just{' '}
        <Link to='/contact'>give us a shout</Link> and we will get back to you
        ASAP.
      </p>
      <ServiceContact service='0' />
    </div>
  );
};

export default ServiceMaintenance;
