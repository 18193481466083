import { Link } from 'react-router-dom';

import './about-contact.styles.scss';

const AboutContact = () => {
  return (
    <div className='about-contact-container'>
      <div className='home-contact-textbox'>
        <h2>We would love to hear from you!</h2>
        <p>
          Have any questions? We'd love to hear them, get in contact with us
          anytime! &rarr;
        </p>
      </div>

      <Link to='/contact' className='btn'>
        Let's Talk &rarr;
      </Link>
    </div>
  );
};

export default AboutContact;
