import ContactForm from '../contact-form/contact-form.component';

import './contact-box.styles.scss';

const Contactbox = () => {
  return (
    <section className='contact-box'>
      <h2>Let's get in touch!</h2>
      <div className='contact-box-container'>
        <div className='contact-box-direct'>
          <h3>Contact us directly</h3>
          <div className='contact-direct'>
            <a href='tel:+12048880296'>
              <ion-icon name='call-outline'></ion-icon>
            </a>
            <p>
              <a className='contact-link' href='tel:+12048880296'>
                (204) 888-0296
              </a>
            </p>
          </div>
          <div className='contact-direct'>
            <a href='mailto:contact@midwestav.ca'>
              <ion-icon name='mail-outline'></ion-icon>
            </a>
            <p>
              <a className='contact-link' href='mailto:contact@midwestav.ca'>
                contact@midwestav.ca
              </a>
            </p>
          </div>
          <div className='contact-direct'>
            <a
              rel='noreferrer'
              noopener='true'
              target='_blank'
              href='https://www.google.com/maps/place/20+Hangar+Line+Rd+%2310,+Winnipeg,+MB+R3J+3Y7/@49.8934385,-97.2339752,21z/data=!4m5!3m4!1s0x52ea7346fda78e31:0x6d4e7ea928f269e4!8m2!3d49.8934186!4d-97.2338666'
            >
              <ion-icon name='home-outline'></ion-icon>
            </a>
            <a
              className='contact-link'
              rel='noreferrer'
              noopener='true'
              target='_blank'
              href='https://www.google.com/maps/place/20+Hangar+Line+Rd+%2310,+Winnipeg,+MB+R3J+3Y7/@49.8934385,-97.2339752,21z/data=!4m5!3m4!1s0x52ea7346fda78e31:0x6d4e7ea928f269e4!8m2!3d49.8934186!4d-97.2338666'
            >
              <div className='contact-address'>
                <p>20 Hangar Line Road #10</p>
                <p>Winnipeg, MB R3J 3Y7</p>
                <p>Canada</p>
              </div>
            </a>
          </div>
          <div className='contact-hours'>
            <h4>Hours</h4>
            <div className='contact-hour'>
              <p>Sunday</p>
              <p>CLOSED</p>
            </div>
            <div className='contact-hour'>
              <p>Monday</p>
              <p>8am - 4:30pm</p>
            </div>
            <div className='contact-hour'>
              <p>Tuesday</p>
              <p>8am - 4:30pm</p>
            </div>
            <div className='contact-hour'>
              <p>Wednesday</p>
              <p>8am - 4:30pm</p>
            </div>
            <div className='contact-hour'>
              <p>Thursday</p>
              <p>8am - 4:30pm</p>
            </div>
            <div className='contact-hour'>
              <p>Friday</p>
              <p>8am - 4:30pm</p>
            </div>
            <div className='contact-hour'>
              <p>Saturday</p>
              <p>CLOSED</p>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
    </section>
  );
};

export default Contactbox;
