import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ServiceContact from '../service-contact/service-contact.component';

import TextMidwest from '../../../assets/textmidwest.png';
import GarminLogo from '../../../assets/garmin-logo.png';
import RCAllenInstruments from '../../../assets/RC-Allen-Instruments.png';
import BendixKing from '../../../assets/BendixKing-Logo.png';
import Luma from '../../../assets/Luma-Logo.png';
import Avidyne from '../../../assets/Avidyne-Logo.png';
import L3Harris from '../../../assets/L3Harris-Logo.png';
import Artex from '../../../assets/artex_logo.png';
import Genesys from '../../../assets/GenesysAerosystems_Logo.png';
import MidContinent from '../../../assets/midcontinent_logo.png';
import Aspen from '../../../assets/aspen_logo.png';
import DavidClark from '../../../assets/dclark-logo.png';
import PSEngineering from '../../../assets/psengineering_logo.png';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './service-sales.styles.scss';

const ServiceSales = () => {
  return (
    <div className='service-sales-container'>
      <div className='parts-container'>
        <div className='aircraft-brands'>
          <LazyLoadImage src={GarminLogo} alt='Garmin Logo' effect='blur' />
          <LazyLoadImage src={Aspen} alt='Aspen Avionics Logo' effect='blur' />
          <LazyLoadImage src={BendixKing} alt='BendixKing Logo' effect='blur' />
          <LazyLoadImage src={Artex} alt='Artex Logo' effect='blur' />
          <LazyLoadImage src={Avidyne} alt='Avidyne Logo' effect='blur' />
          <LazyLoadImage src={L3Harris} alt='L3Harris Logo' effect='blur' />
          <LazyLoadImage src={Luma} effect='blur' alt='Luma Logo' />
          <LazyLoadImage src={Genesys} effect='blur' alt='Genesys Logo' />
          <LazyLoadImage
            src={MidContinent}
            alt='MidContinent Logo'
            effect='blur'
          />
          <LazyLoadImage
            src={PSEngineering}
            effect='blur'
            alt='PS Engineering Logo'
          />
          <LazyLoadImage
            src={RCAllenInstruments}
            alt='RC Allen Logo'
            effect='blur'
          />
          <LazyLoadImage
            Aspen
            src={DavidClark}
            alt='David Clark Logo'
            effect='blur'
          />
        </div>
        <div className='part-sales'>
          <ion-icon name='bar-chart-outline'></ion-icon>
          <h2>Part Sales</h2>
          <p>
            Our expertise in avionics allows us to save you time and help you
            select the most quality parts at the most competitive prices. Allow
            us to be there for you and your projects, so you can expect quality
            and not be let down.
          </p>
        </div>
      </div>
      <div className='part-partnership'>
        <p className='partner'>
          Proud partner of Garmin<span className='trademark'>TM</span>
        </p>
        <div className='part-partnership-logos'>
          <LazyLoadImage src={GarminLogo} alt='Garmin Logo' effect='blur' />
          <span>x</span>
          <LazyLoadImage
            src={TextMidwest}
            alt='Midwest Avionics logo'
            effect='blur'
          />
        </div>
        <p>
          As a proud partner of Garmin, let us help you with all your favourite
          Garmin systems. From the most competitive prices to our quality
          installs, and as a certified Garmin installer, let us take care of
          your favourite systems!
        </p>
        <a
          href='https://www.garmin.com/en-CA/c/aviation/general/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Shop Garmin
        </a>
      </div>
      <p className='service-maintenance-finish'>
        Need a part? Just <Link to='/contact'>give us a shout</Link> and we will
        get back to you ASAP.
      </p>
      <ServiceContact service='2' />
    </div>
  );
};

export default ServiceSales;
