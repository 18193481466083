import ServicesHeaderImage from '../../../assets/services-header-image.jpg';

import './services-header.styles.scss';

const ServicesHeader = () => {
  return (
    <section
      className='services-header'
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${ServicesHeaderImage})`,
      }}
    >
      <article className='services-header-textbox'>
        <h1>Services</h1>
        <p>
          As an <strong>Approved Maintenance Organization</strong> by Transport
          Canada allow us and our team of experts to help you with all your
          service needs. No job is too big or too small, we've got all your
          bases covered.
        </p>
      </article>
      <div className='curve'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
          <path
            fill='#e9ecef'
            fillOpacity='1'
            d='M0,224L120,234.7C240,245,480,267,720,266.7C960,267,1200,245,1320,234.7L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z'
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default ServicesHeader;
