import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import MidwestAvionicsText from '../../../assets/textmidwestavionics.png';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './hero.styles.scss';

const Hero = () => {
  return (
    <section className='hero'>
      <div className='hero-container'>
        <div className='hero-textbox'>
          <LazyLoadImage
            effect='blur'
            src={MidwestAvionicsText}
            alt='Midwest Avionics Logo'
          />
          <p>
            Covering all your avionics needs, with work and products that we
            stand behind!
          </p>
        </div>
        <div className='hero-buttons'>
          <Link to='/contact' className='btn btn--contact'>
            Get a Quote
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;
